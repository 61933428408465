import React, { useState, useRef } from 'react'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import {
  Box,
  Container,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { firstContent } from '../utils/content'
import CloseIcon from './graphics/Close'
import EyebrowWithType from './EyebrowWithType'
import RiseButton from './RiseButton'
import Image from './PreviewCompatibleImage'
import { TYPES } from '../utils/constants'
import IntersectBox from './IntersectBox'
import EpisodesTakeover from './EpisodesTakeover'
import seriesTitleSlug from '../utils/seriesTitleSlug'
import EpisodeListCarousel from './EpisodeListCarousel'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '75vh',
    width: '100%',
    minHeight: '520px',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: '630px',
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      height: '90%',
    },
  },
  copyContainer: {
    transition: 'all .4s ease',
    transformOrigin: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&>div': {
      transition: 'all .35s ease',
      opacity: 0,
      transform: 'translate(0,25%)',
    },
    '&$inView': {
      '& >div': {
        opacity: 1,
        transform: 'translate(0,0%) ',
        '&:nth-child(2)': {
          transitionDelay: '0.1s',
        },
        '&:nth-child(3)': {
          transitionDelay: '0.15s',
        },
        '&:nth-child(4)': {
          transitionDelay: '0.2s',
        },
      },
    },
    '&$active': {
      '& $activeHidden': {
        opacity: 0,
      },
    },
  },
  active: {},
  activeHidden: { transition: 'opacity 0.3s ease' },
  imageContainer: {
    height: '100%',
    right: 0,
    top: 0,
    transition: 'transform .4s ease-out',
    willChange: 'transform',
    transform: 'translate3d(10%,0,0) scale(1)',
    width: '100%',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      transform: 'translate3d(0%,0,0) scale(1)',
    },
    '& $gatsby-image-wrapper': {
      height: '100%',
    },
    '&$active': {
      transform: 'translate3d(0,0,0) scale(1.1)',
    },
  },
  mask: {
    background:
      'linear-gradient(93.6deg, #0C0F11 1.58%, rgba(12, 15, 17, 0) 58.17%)',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      background:
        'linear-gradient(0deg, #0C0F11 20.58%, rgba(12, 15, 17, 0) 95.17%)',
    },
  },
  darkOverlay: {
    background: 'black',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 0.5s ease',
    width: '100%',
    zIndex: 1,
    opacity: 0,
    '&$active': {
      opacity: 0.7,
    },
  },
  inView: {},
  episodeContainer: {
    opacity: 0,
    pointerEvents: 'none',
    transform: 'translate(0,10%)',
    transition: 'all 0.5s ease',
    '&$active': {
      opacity: 1,
      pointerEvents: 'initial',
      transform: 'translate(0,0%)',
      transitionDelay: '0.2s',
    },
  },
}))

const SeriesBlock = ({
  node,
  width,
  imageStyle,
  onHeroImageLoaded = () => {},
}) => {
  const classes = useStyles()
  const [active, setActive] = useState(false)
  const [inView, setInView] = useState(false)
  const copyRef = useRef(null)
  const seriesType = node.fields.content.reduce(
    (acc, { frontmatter: { templateKey } }) => {
      if (TYPES[templateKey] === acc) return acc
      return 'mixed'
    },
    TYPES[node.fields.content[0].frontmatter.templateKey],
  )
  const externalUrl = firstContent(node)?.frontmatter
    ? firstContent(node).frontmatter.externalUrl
    : null
  const titleSlug = seriesTitleSlug(node.fields.slug)

  const copyStyle = {
    transform: active
      ? `translate(0,-${copyRef.current.offsetTop - 20}px) scale(0.75)`
      : 'translate(0,0) scale(1)',
  }
  const xsLayout = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <IntersectBox
      allowTriggerReset
      type="fadeTrigger"
      threshold={0.7}
      onThresholdMet={() => {
        setInView(true)
      }}
      onExitThreshold={() => setInView(false)}
    >
      <Box id={titleSlug} position="relative" className={classes.main}>
        <Box
          position="absolute"
          className={[
            classes.imageContainer,
            active ? classes.active : null,
          ].join(' ')}
          style={xsLayout ? imageStyle.xs : {}}
        >
          <Box
            className={[classes.mask, active ? classes.active : null].join(' ')}
          />
          <Image
            style={{ height: '100%' }}
            imageInfo={node.frontmatter.heroImg}
            onLoad={onHeroImageLoaded}
          />
          <Box
            className={[
              classes.darkOverlay,
              active ? classes.active : null,
            ].join(' ')}
          />
        </Box>
        <Container
          maxWidth="xl"
          className={classes.centered}
          position="relative"
        >
          <Box
            className={[
              classes.copyContainer,
              active ? classes.active : null,
              inView ? classes.inView : null,
            ].join(' ')}
            position="absolute"
            ref={copyRef}
            style={copyStyle}
          >
            <Box mb={2}>
              <EyebrowWithType type={seriesType} />
            </Box>
            <Box mb={3.75}>
              <Typography variant="h1">{node.frontmatter.title}</Typography>
            </Box>
            <Box
              mb={3.75}
              style={{ maxWidth: '550px' }}
              className={classes.activeHidden}
            >
              <Typography variant="body2">
                {node.frontmatter.description}
              </Typography>
            </Box>
            <Box display="flex" mb={6.25} className={classes.activeHidden}>
              <Box mr={3}>
                {firstContent(node)?.fields ? (
                  <RiseButton
                    type={seriesType}
                    to={externalUrl || firstContent(node).fields.slug}
                    linkClass="series-primary-cta"
                    target={externalUrl ? '_blank' : '_self'}
                  >
                    {`${seriesType === 'read' ? 'Read' : 'Play'} Now`}
                  </RiseButton>
                ) : null}
              </Box>
              <Box>
                <RiseButton
                  flat
                  onClick={() => {
                    setActive(true)
                  }}
                  buttonClass="series-episodes-cta"
                >
                  {seriesType === 'read' ? 'Posts' : 'Episodes'}
                </RiseButton>
              </Box>
            </Box>
          </Box>
          <Box
            className={`${classes.episodeContainer} ${
              active ? classes.active : ''
            }`}
            pt={15}
          >
            <Box mb={3.75} display="flex" justifyContent="space-between">
              <Typography variant="h5" component="h2">
                {seriesType === 'read' ? 'Posts' : 'Episodes'}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setActive(false)
                }}
              />
            </Box>
            <EpisodeListCarousel
              currentEpisodeId={null}
              content={node.fields.content.map((contentItem) => ({
                node: contentItem,
              }))}
              arrowOffset={[10, 0, 25, 0, 20]}
              sorted
            />
          </Box>
        </Container>
        {/* <Box
          className={[
            classes.rightOverlay,
            active ? classes.active : null,
          ].join(' ')}
        /> */}
      </Box>
      {isWidthDown('sm', width) && (
        <EpisodesTakeover
          node={node}
          active={active}
          seriesType={seriesType}
          setActive={setActive}
        />
      )}
    </IntersectBox>
  )
}

SeriesBlock.propTypes = {
  node: PropTypes.shape({
    frontmatter: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      content: PropTypes.array,
      heroImg: PropTypes.shape({}),
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.shape({
            slug: PropTypes.string,
          }),
          frontmatter: PropTypes.shape({
            templateKey: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
  onHeroImageLoaded: PropTypes.func,
  width: PropTypes.string.isRequired,
  imageStyle: PropTypes.shape({ xs: PropTypes.shape({}) }),
}

SeriesBlock.defaultProps = {
  imageStyle: { xs: {} },
  onHeroImageLoaded: () => {},
}

export default withWidth()(SeriesBlock)
