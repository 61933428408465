import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { makeStyles, Box, CircularProgress } from '@material-ui/core'
import Layout from '../../components/Layout'
import SeriesBlock from '../../components/SeriesBlock'
import Footer from '../../components/Footer'
import '../../utils/transitions.scss'
import NewsletterBlock from '../../components/NewsletterBlock'
import AboutBlock from '../../components/AboutBlock'
import seriesTitleSlug from '../../utils/seriesTitleSlug'

const useStyles = makeStyles(() => ({
  main: {
    transition: 'all 0.45s ease',
    opacity: 0,
    transform: 'translate(-20px,20px)',
  },
  entered: {
    opacity: 1,
    transform: 'translate(0px,0px)',
  },
  entering: {
    opacity: 0,
    transform: 'translate(-20px,20px)',
  },
  loaderBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  exiting: { opacity: 0, transform: 'translate(0px,0px)' },
  exited: { opacity: 0, transform: 'translate(0px,0px)' },
}))

export const SeriesTemplate = ({ series, transitionStatus }) => {
  const classes = useStyles()
  const [length, setLength] = useState(2)
  const [loaded, setLoaded] = useState(0)

  useEffect(() => {
    if (loaded === length) {
      setLength((prev) => prev + 1)
    }
  }, [loaded])

  return (
    <Box
      className={`${classes.main} ${classes[transitionStatus]}`}
      id="series-box"
    >
      {series.slice(0, length).map(({ node }, index) => (
        <SeriesBlock
          key={node.id}
          node={node}
          imageStyle={
            index === 1 ? { xs: { width: '1000px', left: '-450px' } } : {}
          }
          onHeroImageLoaded={() => setLoaded((prev) => prev + 1)}
        />
      ))}
      {loaded < series.length - 1 && (
        <Box className={classes.loaderBox}>
          <CircularProgress />
        </Box>
      )}
      <AboutBlock />
      <NewsletterBlock />
      <Footer />
    </Box>
  )
}

SeriesTemplate.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transitionStatus: PropTypes.string,
}

SeriesTemplate.defaultProps = {
  transitionStatus: 'entered',
}

const SeriesPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark

  /** Filter out empty series. */
  const [series, setSeries] = useState(edges.filter(({ node }) => node.fields.content.length))

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const anchorTitle = urlParams.get('title')

    if (anchorTitle) {
      setSeries((prevSeries) => prevSeries.sort(({ node: node1 }, { node: node2 }) => {
        if (seriesTitleSlug(node1.fields.slug) === anchorTitle) return -1
        if (seriesTitleSlug(node2.fields.slug) === anchorTitle) return 1
        return 0
      }))
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })

    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebPage',
        breadcrumb: 'Home > Series',
        id: 'series',
        name: 'Series Page',
        page_category: 'Series',
      },
    })
  }, [])

  return (
    <Layout includeFooter={false} hasLoader>
      <TransitionState>
        {({ transitionStatus }) => (
          <SeriesTemplate
            series={series}
            transitionStatus={transitionStatus}
          />
        )}
      </TransitionState>
    </Layout>
  )
}

SeriesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              heroImg: PropTypes.shape({}),
            }),
          }),
        }),
      ),
    }),
  }).isRequired,
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "//series//" } } }
      sort: { fields: fields___autoSeriesNumber }
    ) {
      edges {
        node {
          id
          fields {
            slug
            content {
              id
              fields {
                slug
                autoEpisodeNumber
              }
              frontmatter {
                id
                heroImg {
                  childImageSharp {
                    fluid(maxWidth: 686, maxHeight: 386) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 686, maxHeight: 386) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                externalUrl
                title
                templateKey
                hidePost
              }
            }
          }
          frontmatter {
            id
            heroImg {
              childImageSharp {
                fluid(maxWidth: 2048, maxHeight: 1152) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            title
            titleUnderline
            content
          }
        }
      }
    }
  }
`

export default SeriesPage
