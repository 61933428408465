import React, { useState } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import { TYPES } from '../utils/constants'
import ContentCard from './ContentCard'

const useStyles = makeStyles((theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  episodes: {
    transition: 'opacity .5s ease',
    padding: '60px 24px',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.9)',
    '&$active': {
      [theme.breakpoints.down('sm')]: {
        overflowY: 'scroll',
        minHeight: '100%',
      },
    },
    '&$active $episodeCard': {
      opacity: 1,
      transform: 'translate(0px,0)',
      '&:nth-child(1)': {
        transition: '0.4s ease 0.3s',
      },
      '&:nth-child(2)': {
        transition: '0.4s ease 0.5s',
      },
      '&:nth-child(3)': {
        transition: '0.4s ease 0.7s',
      },
      '&:nth-child(4)': {
        transition: '0.4s ease 0.9s',
      },
    },
    '&::-webkit-scrollbar': {
      // width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#aaa',
    },
  },
  episodeCard: {
    opacity: 0,
    transform: 'translate(0px,8%)',
    transition: '0.3s ease',
  },
  active: {},
  icon: {
    pointerEvents: 'all',
    background: 'rgba(0,0,0,0.05)',
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
  largeIcon: {
    fontSize: '32px',
  },
}))

const EpisodesTakeover = ({
  node,
  active,
  setActive,
  seriesType,
}) => {
  const classes = useStyles()
  const [isEntered, setIsEntered] = useState(false)
  return (
    <Dialog
      fullScreen
      open={active}
      onEntering={() => setIsEntered(true)}
      onExit={() => setIsEntered(false)}
    >
      <Box
        className={[classes.episodes, isEntered ? classes.active : null].join(
          ' ',
        )}
      >
        <Box
          mb={3.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" style={{ color: 'white' }}>
            {seriesType === 'read' ? 'Posts' : 'Episodes'}
          </Typography>
          <IconButton
            aria-label="close"
            className={`${classes.icon}`}
            onClick={() => {
              setActive(false)
            }}
          >
            <CloseIcon className={classes.largeIcon} />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          {node.fields.content.sort(
            (
              { fields: { autoEpisodeNumber: a } },
              { fields: { autoEpisodeNumber: b } },
            ) => a - b,
          ).map((item) => {
            const { frontmatter, id, fields } = item
            const { externalUrl } = frontmatter

            return (
              <Box
                key={`${id}-episode-takeover`}
                className={classes.episodeCard}
                mb={6}
              >
                <ContentCard
                  target={externalUrl ? '_blank' : '_self'}
                  to={externalUrl || fields.slug}
                  thumbnail={frontmatter.heroImg ? frontmatter.heroImg : frontmatter.thumbnail}
                  type={TYPES[frontmatter.templateKey]}
                  title={`${fields.autoEpisodeNumber.toString().padStart(2, '0')}. ${frontmatter.title}`}
                  eyebrow={false}
                  withCarousel
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </Dialog>
  )
}

EpisodesTakeover.propTypes = {
  node: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      heroImg: PropTypes.shape({}),
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            templateKey: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
  setActive: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  seriesType: PropTypes.oneOf(['listen', 'read', 'watch']).isRequired,
}

export default EpisodesTakeover
