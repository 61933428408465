import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'


const Close = ({ onClick }) => (
  <>
    <Hidden xsUp><p>Close</p></Hidden>
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      preserveAspectRatio="xMidYMid"
      onClick={onClick}
      style={{ cursor: 'pointer', fontSize: '28px' }}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.8572 6.58663C7.62928 6.37036 7.32443 6.24962 7.00726 6.25C6.76326 6.24945 6.52468 6.32053 6.32223 6.45409C6.11978 6.58765 5.96273 6.77758 5.8713 6.99942C5.77988 7.22126 5.75826 7.46486 5.80923 7.69885C5.86021 7.93284 5.98144 8.14652 6.15732 8.31236L14.268 15.9839L6.15732 23.6553C6.0347 23.7631 5.9355 23.894 5.86564 24.0401C5.7958 24.1863 5.75673 24.3448 5.75079 24.5062C5.74485 24.6675 5.77216 24.8284 5.83108 24.9791C5.89 25.1299 5.97932 25.2674 6.0937 25.3835C6.20807 25.4997 6.34516 25.592 6.49676 25.6549C6.64836 25.7179 6.81135 25.7502 6.97598 25.75C7.14062 25.7498 7.30351 25.717 7.45493 25.6536C7.60634 25.5902 7.74316 25.4975 7.8572 25.3811L16 17.6814L24.1428 25.3811C24.2568 25.4975 24.3937 25.5902 24.5451 25.6536C24.6965 25.717 24.8594 25.7498 25.024 25.75C25.1886 25.7502 25.3516 25.7179 25.5032 25.6549C25.6548 25.592 25.7919 25.4997 25.9063 25.3835C26.0207 25.2674 26.11 25.1299 26.1689 24.9791C26.2278 24.8284 26.2551 24.6675 26.2492 24.5062C26.2433 24.3448 26.2042 24.1863 26.1343 24.0401C26.0645 23.894 25.9653 23.7631 25.8427 23.6553L17.732 15.9839L25.8427 8.31236C26.0186 8.14651 26.1398 7.93284 26.1908 7.69885C26.2417 7.46486 26.2201 7.22126 26.1287 6.99942C26.0373 6.77758 25.8802 6.58765 25.6778 6.45409C25.4753 6.32053 25.2367 6.24945 24.9927 6.25C24.6756 6.24962 24.3707 6.37036 24.1428 6.58663L16 14.2863L7.8572 6.58663Z" fill="white" />
    </SvgIcon>
  </>
)

Close.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Close
